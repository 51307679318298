<template>
  <div class="mt-10 xl:mx-56 lg:mx-28 sm:mx-10 mx-4">
    <div class ="flex justify-between align-middle bg-white rounded-t px-8 pt-8 dark:bg-primaryDark">
      <div class="flex flex-row">
        <div class="flex flex-row dark:text-white pl-2 mr-2">
          <div class="flex items-center justify-center">
            <font-awesome-icon icon="fa-solid fa-building" size="lg"/>
          </div>
          <h2 class="m-2 sm:text-xl text-lg self-center">Places</h2>
        </div>
        <router-link title="Switch to Spaces" :to="{ name: 'Spaces'}">
          <div class="flex flex-row text-gray-400 hover:text-gray-700 dark:text-gray-500 dark-hover:text-gray-300">
            <div class="flex items-center justify-center">
              <font-awesome-icon icon="fa-solid fa-location-dot" size="lg"/>
            </div>
            <h2 class="m-2 sm:text-xl text-lg self-center">Spaces</h2>
          </div>
        </router-link>
      </div>
      <router-link :to="{ name: 'AddPlace'}" v-if="store.getters.isLoggedIn">
        <AddButton title="Add Place">
          <font-awesome-icon icon="fa-solid fa-plus"/>
        </AddButton>
      </router-link>
    </div>
    <DynamicTable :filters="venueFilters" :link="profileViewLink" @sort="onSortParamEntry"  @next="onNextPage" @prev="onPreviousPage" :searchFunc="onSearchParamEntry" :update="update" />
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import { useRouter } from "vue-router";
import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";
import AddButton from "../components/inputs/AddButton";

export default {
  name: "places",
  components: { DynamicTable, AddButton },
  setup() {
    const store = useStore()
    const router = useRouter()

    store.dispatch('dynamicTableLoadVenues')

    function onVenueView(id){
      router.push({ name: 'Venue', params: { id }})
    }

    return {
      onVenueView,
      store,
      venueFilters: computed(() => store.getters.getVenueFilters),
      profileViewLink: computed(() => {
        return {func: onVenueView, fieldName: "Name"}
      }),
      async onSearchParamEntry(searchString) {
        store.commit('setDynamicTableSearchParam', searchString)
        await store.dispatch('dynamicTableLoadVenues')
      },
      async update() {
        await store.dispatch('dynamicTableLoadVenues')
      },
      async onSortParamEntry(key) {
        if (key === 'Name'){
          store.commit('setVenueSortBy', 'name')
        } else if (key === 'venueType') {
          store.commit('setVenueSortBy', 'venue_type')
        } else if (key === 'Country') {
          store.commit('setVenueSortBy', 'country')
        }
        await store.dispatch('dynamicTableLoadVenues')
      },
      onNextPage: () => store.dispatch('venuesPageNext'),
      onPreviousPage: () => store.dispatch('venuesPagePrevious')
    }
  }
}
</script>

<style scoped>

</style>
