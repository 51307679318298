<template>
  <tr class="dark:text-white text-gray-900 bg-white border-t dark:bg-gray-900 dark:border-gray-700">
    <td class="py-4 px-6" v-for="(field, idx) in fields" :key="idx">
    
      <div v-show="(field =='name')"> 
          <div class="flex items-center">
              <div class="h-10 w-10 flex-shrink-0">
                  <img class="h-full w-full rounded-full" :src="item['image']" alt="" />
              </div>
              <div class="ml-3">
                  <p class="whitespace-no-wrap">{{item['name']}}</p>
              </div>
          </div>
      </div>
      <p v-show="(field == 'date')">{{ item[field] }} </p>
      <p v-show="( field == 'reason')">{{ item[field] }}</p>
      
    </td>
    <td class="py-4 px-6 flex">
      <button @click="$emit('onSelect', item)"><PencilIcon class="h-5 pt-1 w-5 dark:text-white"/></button>
      <button @click="$emit('onSelect', item)"><TrashIcon class="h-5 pt-1 w-5 dark:text-white"/></button>
    </td>
  </tr>
</template>

<script>
import {PencilIcon, TrashIcon} from '@heroicons/vue/outline';
export default {
  name: "MedicalTableUnit",
  components: {PencilIcon,TrashIcon},
  props: {
    fields: Array,
    item: Object
  },
  setup() {

  }
}
</script>
