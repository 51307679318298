<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton :settingMenu="true" />
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <SettingSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60 cursor-not-allowed pointer-events-none">
              <div class="flex items-center gap-2">
                <font-awesome-icon
          :icon="['far', 'bell']"
          class="text-grey-400 text-md dark:text-white font-normal text-black flex items-center py-1 px-0 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
          aria-hidden="true"
        />

              
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Notifications
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>

            
              <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
                <p class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white">
                  Reminders
                </p>
                <NotificationTable
                  :values="Reminders"
                  :fields="['type', 'browser', 'email','sms']"
                />
              </div>
              <div class="w-full mb-5" id="inputList-2" v-if="!isMobile">
                <p class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white">
                  Requests
                </p>
                <NotificationTable
                  :values="Requests"
                  :fields="['type', 'browser', 'email','sms']"
                />
              </div>
              <div class="w-full mb-5" id="inputList-3" v-if="!isMobile">
                <p class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white">
                  Tags
                </p>
                <NotificationTable
                  :values="Tags"
                  :fields="['type', 'browser', 'email','sms']"
                />
              </div>

              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Reminders</p>
                </template>
                <template v-slot:content>
                  <NotificationTable
                  :values="Reminders"
                  :fields="['type', 'browser', 'email','sms']"
                />
                </template>
              </app-accordion>

              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Requests</p>
                </template>
                <template v-slot:content>
                  <NotificationTable
                  :values="Requests"
                  :fields="['type', 'browser', 'email','sms']"
                />
                </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Tags</p>
                </template>
                <template v-slot:content>
                  <NotificationTable
                  :values="Tags"
                  :fields="['type', 'browser', 'email','sms']"
                />
                </template>
              </app-accordion>
            
              <div class="flex justify-end w-full mt-5">
                <SaveCancelForm version="1" :display="true" justify-orientation="end" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import NotificationTable from "@/components/profile/components/profileSettings/NotificationTable/NotificationTable";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

const Reminders = [
  {
    'type':'Rehearsal reports',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Rehearsal calls',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Show reports',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Meeting invitation',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Meeting minutes',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Join requests',
    'browser':true,
    'email':false,
    'sms':true
  },
];

const Requests=[
  {
    'type':'Place requests',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Company requests',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Production requests',
    'browser':true,
    'email':true,
    'sms':true
  },
  {
    'type':'Work requests',
    'browser':true,
    'email':true,
    'sms':true
  },
];

const Tags=[
  {
    'type':'Place tags',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Company tags',
    'browser':true,
    'email':true,
    'sms':false
  },
  {
    'type':'Thing tags',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Work tags',
    'browser':true,
    'email':false,
    'sms':true
  },
  {
    'type':'Production tags',
    'browser':true,
    'email':false,
    'sms':true
  },
];
export default {
  name: "Notifications",
  components: {
    ExpandableBlockContent,
    SettingSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    NotificationTable,
    AppAccordion
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      Reminders,
      Requests,
      Tags
    };
  },
  computed: {
    pageContent: function () {
      return "Jarlsberg monterey jack danish fontina swiss cow gouda emmental parmesan. Edam mozzarella danish fontina cut the cheese port-salut mozzarella jarlsberg.";
    },
  },
};
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #404040;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #404040;
}
</style>
