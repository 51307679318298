<template>
  <tr class="dark:text-white text-gray-900 bg-white border-t dark:bg-gray-900 dark:border-gray-700">
    <td class="py-4 px-6" v-for="(field, idx) in fields" :key="idx">
    
      <p v-show="(field =='type')">{{item[field]}}</p>
      <p v-show="( field == 'browser' || field == 'email' || field == 'sms')"><input type="checkbox" :checked="item[field] " > </p>
      
      
    </td>
   
  </tr>
</template>

<script>
// import {PencilIcon, TrashIcon} from '@heroicons/vue/outline';
export default {
  name: "NotificationTableUnit",
  // components: {PencilIcon,TrashIcon},
  props: {
    fields: Array,
    item: Object
  },
  setup() {

  }
}
</script>
