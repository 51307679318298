<template>
  <div class="mb-8">
    <main>
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton :settingMenu="true" />
      </div> 
      
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
      <SettingSidebar
        class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
        :subNav="true"
      />
    </div>
    <div
      class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
    >
  <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60 cursor-not-allowed pointer-events-none" >
    <div class="flex items-center gap-2 ">
      <font-awesome-icon :icon="['fas', 'anchor']"  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0  h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
      <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
        Navigation
      </h1>
    </div>
    <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6 ">
      <!-- <ExclamationCircleIcon class="w-4" /> -->
     
  
    </div>

    <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                Select page to navigate to on login
                </p>
                <div class="w-full flex  gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                      
                     value="My Profile"
                      textFormate="capitalize"
                       class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 "
                      :options="['My Profile', 'Strap']"
                    />
                  </div>
                </div>
              </div>
    <app-accordion class="mt-8" v-if="isMobile">

        <template v-slot:title>
          <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Select page to navigate to on login</p>
          
        </template>
        <template v-slot:content>
        <div class="flex w-full">
          <SelectInput
                      
                     value="My Profile"
                      textFormate="capitalize"
                       class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mb-5"
                      :options="['My Profile', 'Strap']"
                    />
        </div>
        </template>
      </app-accordion>
     


  
    <div class="flex justify-end w-full ">
        <SaveCancelForm
               version="1"
               :display="true"
               justify-orientation="end"
              
            />
      </div>
    </div>
  </div>
  </div>
  </div>
  </main></div>
</template>

<script>
// import { computed } from 'vue'
import {useStore} from "vuex";
import {computed,ref} from "vue";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import SelectInput from "@/components/inputs/SelectInput";
export default {
  name: "Navigation",
  components: {
    SettingSidebar,ProfileHeader ,  
    EditProfileHeaderButton,
    SelectInput,
    SaveCancelForm,
    AppAccordion
     },
  setup(){
    const store = useStore()
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      contacts: [{'title':'Marie Kondo','subtittle':'Freewave','role':'Japan manager','contact':false,'photos':true,'programme':true,'travel':true},{'title':'Gunnar Gísli','subtittle':'CAI','role':'Iceland agent','contact':true,'photos':true,'programme':false,'travel':false},{'title':'Lily Allen','subtittle':'London International','role':'London agent','contact':false,'photos':true,'programme':false,'travel':true},{'title':'John Cho','subtittle':'LA Dancers','role':'LA Agent','contact':false,'photos':true,'programme':false,'travel':true}],
    
    }
  },
  computed: {
      pageContent: function () {
        return 'The big cheese fromage cheese and wine. Swiss cottage cheese halloumi boursin roquefort cheese strings danish fontina blue castello. Stilton cheese strings red leicester everyone loves red leicester hard cheese cream cheese manchego. Manchego the big cheese feta airedale.'
      },
    },
}
</script>

<style scoped>

</style>
