<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton :settingMenu="true" />
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <SettingSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60 cursor-not-allowed pointer-events-none">
              <div class="flex items-center gap-2">
              <svg width="24" height="24" viewBox="0 0 16 20" class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-6 md:h-8 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0ZM14 10L14.002 18H2V10H14ZM5 8V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5Z" fill="currentColor" />
              </svg>

              
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Privacy
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>

              <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Default audience
                </p>
                <p class="dark:text-white">
                  Here you can set your default audience. This audience refers to who will
                  be able to see your profile information.
                </p>
                <div class="w-1/2">
                  <div class="col-span-2 flex items-end">
                    <DropdownWithCreate
                      :value="null"
                      :options="defaultAudience"
                      heading="Who is your default audience?"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Blocked users
                </p>
                <BlockUserTable
                  header="Emergency Contacts"
                  :values="blockUser"
                  :fields="['name', 'date', 'reason']"
                />
              </div>
              <div class="w-full my-10" id="inputList-2" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Search results
                </p>
                <p class="dark:text-white">Appear in search results filtered by</p>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Country</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in "
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer "
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Physical information</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class=" ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                  </div>
                </div>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">City</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Diversity information</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                  </div>
                </div>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Job title</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Skills</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white"> Default audience</p>
                </template>
                <template v-slot:content>
                  <p class="dark:text-white">
                  Here you can set your default audience. This audience refers to who will
                  be able to see your profile information.
                </p>
                <div class="w-full">
                  <div class="col-span-2 flex items-end">
                    <DropdownWithCreate
                      :value="null"
                      :options="defaultAudience"
                      heading="Who is your default audience?"
                      class="w-full"
                    />
                  </div>
                </div>
                </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Blocked users</p>
                </template>
                <template v-slot:content>
                  <BlockUserTable
                  header="Emergency Contacts"
                  :values="blockUser"
                  :fields="['name', 'date', 'reason']"
                />
                </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Search results</p>
                </template>
                <template v-slot:content>
                  <p class="dark:text-white">
                    Appear in search results filtered by
                </p>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Country</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in "
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer "
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Physical information</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class=" ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                  </div>
                </div>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">City</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Diversity information</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                  </div>
                </div>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Job title</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-xs text-gray-700 flex-1 dark:text-white">Skills</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer flex-0"
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                  </div>
                </div>
              
                </template>
              </app-accordion>
              <div class="flex justify-end w-full mt-5">
                <SaveCancelForm version="1" :display="true" justify-orientation="end" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import DropdownWithCreate from "@/components/inputs/DropdownWithCreate";
import BlockUserTable from "@/components/profile/components/profileSettings/blockUserTable/BlockUserTable";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

const userURL = window.location.href;
const defaultAudience=[
                        'Public',
                        'Registered Showdeck users',
                        'Friends',
                        'Colleagues',
                        'Private',
                      ];
const blockUser = [
  {
    image: "https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116_1280.jpg",
    name: "John Hamm",
    date: "11/12/2020",
    reason: "spam",
  },
  {
    image: "https://cdn.pixabay.com/photo/2016/01/25/19/48/man-1161337_640.jpg",
    name: "Mark Wahlberg",
    date: "11/12/2020",
    reason: "spam",
  },
  {
    image: "https://cdn.pixabay.com/photo/2016/08/25/19/37/man-1620502_640.jpg",
    name: "Philip Green",
    date: "11/12/2020",
    reason: "spam",
  },
  {
    image: "https://cdn.pixabay.com/photo/2022/12/24/21/14/portrait-7676482_640.jpg",
    name: "Stanley Hudson",
    date: "11/12/2020",
    reason: "spam",
  },
];
export default {
  name: "Privacy",
  components: {
    ExpandableBlockContent,
    SettingSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    DropdownWithCreate,
    BlockUserTable,
    AppAccordion
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      userURL,
      fullName: store.getters.getUserFullName,
      blockUser,
      defaultAudience,
    };
  },
  computed: {
    pageContent: function () {
      return "Jarlsberg monterey jack danish fontina swiss cow gouda emmental parmesan. Edam mozzarella danish fontina cut the cheese port-salut mozzarella jarlsberg.";
    },
  },
};
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #404040;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #404040;
}
</style>
