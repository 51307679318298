<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton :settingMenu="true"/>
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <SettingSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60 cursor-not-allowed pointer-events-none ">
              <div class="flex items-center gap-2">
                <svg  viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-9 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out">
                   <path d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7H13.1667C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="2" />
                </svg>
      
              
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Subscription
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>
              <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
              <div class="w-full">
                <div class="w-full px-3 mb-6 lg:mb-0 lg:w-9/12 lg:flex-none  border rounded-lg">
                    <div class="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                      <div class="flex-auto p-4">
                          <div class="flex flex-wrap -mx-3">
                             <div class="max-w-full px-3 mt-12  lg:mt-0 lg:w-5/12 lg:flex-none h-auto">
                                  <div class="h-full  rounded-xl">
                                  <img src="https://img.staticmb.com/mbimages/project/Photo_h240_w0/2022/02/09/Project-Photo-4-Global-Square-Pune-5107449_1500_2000_240_0.jpg"/>
                                      <div class="flex flex-col items-center justify-center relative text-center">
                                        <h5 class="font-bold mb-2 mt-2">Basic</h5>
                                      <p>Your next payment is due: 01/11/2023</p>
                                      </div>
                                      
                                    </div>
                                </div>
                              <div class="max-w-full px-3 lg:w-1/2 lg:flex-none">
                                  <div class="flex flex-col h-full">
                                      <p class="pt-2 mb-1 font-semibold">Built by developers</p>
                                      <ul class="list-disc">
                                        <li class="ml-5">Manage Companies and Places </li>
                                        <li class="ml-5">Use search filters for all categories </li>
                                        <li class="ml-5"> Access a wide range of industry statistics</li>
                                      </ul>
                                  </div>
                                  </div>
                                  
                          </div>
                      </div>
                    </div>
               
               
                </div>
              
              <div class="flex my-5">
                <button type="button" class="bg-transparent border border-black focus:outline-none font-medium hover:bg-black hover:border-black hover:text-white inline-flex items-center mr-2 px-6 py-2 rounded-md shadow-sm sm:mr-4 text-base text-black">Change Plan</button>
                <button type="button" class="bg-transparent border border-red-500 focus:outline-none font-medium hover:bg-red-500 hover:border-red-400 hover:text-white inline-flex items-center mr-2 px-6 py-2 rounded-md shadow-sm sm:mr-4 text-base text-gray-700 text-red-600">Delete Account</button>
              </div>
              
              </div>
              </div>
              <div class="w-full my-10" id="inputList-2" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Emails
                </p>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <label for="toggle" class="text-lg text-black-700 flex-1 dark:text-white">Receive invoices by email</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in "
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer "
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                 </div> 
              </div>
              <div class="w-full my-10" id="inputList-3" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                Payment settings
                </p>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                  heading="Payment method"
                  :tooltip="true"
                  textFormate="capitalize"
                  toolText="Payment method"
                  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                  :options="['Paypal','Strap']"
                />            
                  </div>
                 </div> 
                 <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                  heading="Default currency"
                  :tooltip="true"
                  textFormate="capitalize"
                  toolText="Default currency"
                  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                  :options="['Paypal','Strap']"
                />            
                  </div>
                 </div> 
              
              </div>

              <app-accordion class="mt-8" v-if="isMobile">
                  <template v-slot:title>
                    <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Your current subscription</p>
                    
                  </template>
                  <template v-slot:content>
                    <div class="w-full">
                <div class="w-full px-3 mb-6 lg:mb-0 lg:w-9/12 lg:flex-none  border rounded-lg">
                    <div class="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                      <div class="flex-auto p-4">
                          <div class="flex flex-wrap -mx-3">
                             <div class="max-w-full px-3 mt-12  lg:mt-0 lg:w-5/12 lg:flex-none h-auto">
                                  <div class="h-full  rounded-xl">
                                  <img src="https://img.staticmb.com/mbimages/project/Photo_h240_w0/2022/02/09/Project-Photo-4-Global-Square-Pune-5107449_1500_2000_240_0.jpg"/>
                                      <div class="flex flex-col items-center justify-center relative text-center">
                                        <h5 class="font-bold mb-2 mt-2">Basic</h5>
                                      <p>Your next payment is due: 01/11/2023</p>
                                      </div>
                                      
                                    </div>
                                </div>
                              <div class="max-w-full px-3 lg:w-1/2 lg:flex-none">
                                  <div class="flex flex-col h-full">
                                      <p class="pt-2 mb-1 font-semibold">Built by developers</p>
                                      <ul class="list-disc">
                                        <li class="ml-5">Manage Companies and Places </li>
                                        <li class="ml-5">Use search filters for all categories </li>
                                        <li class="ml-5"> Access a wide range of industry statistics</li>
                                      </ul>
                                  </div>
                                  </div>
                                  
                          </div>
                      </div>
                    </div>
               
               
                </div>
              
              <div class="flex my-5 justify-center">
                <button type="button" class="bg-transparent border border-black focus:outline-none font-medium hover:bg-black hover:border-black hover:text-white inline-flex items-center mr-2 px-6 py-2 rounded-md shadow-sm sm:mr-4 text-sm text-black">Change Plan</button>
                <button type="button" class="bg-transparent border border-red-500 focus:outline-none font-medium hover:bg-red-500 hover:border-red-400 hover:text-white inline-flex items-center mr-2 px-6 py-2 rounded-md shadow-sm sm:mr-4 text-sm text-gray-700 text-red-600">Delete Account</button>
              </div>
              
              </div>
                  </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                  <template v-slot:title>
                    <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Emails</p>
                    
                  </template>
                  <template v-slot:content>
                    <div class="w-full flex my-5 gap-5">
                  <div class="w-full flex">
                    <label for="toggle" class="text-lg text-black-700 flex-1 dark:text-white">Receive invoices by email</label>
                    <div
                      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in "
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle"
                        class="ml-5 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer "
                      />
                      <label
                        for="toggle"
                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                   
                  </div>
                 </div> 
                  </template>
              </app-accordion>

              <app-accordion class="mt-8" v-if="isMobile">
                  <template v-slot:title>
                    <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white"> Payment settings</p>
                    
                  </template>
                  <template v-slot:content>
                    <div class="w-full flex my-1 gap-5">
                  <div class="w-full flex">
                    <SelectInput
                  heading="Payment method"
                  :tooltip="true"
                  textFormate="capitalize"
                  toolText="Payment method"
                  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                  :options="['Paypal','Strap']"
                />            
                  </div>
                 </div> 
                 <div class="w-full flex my-1 gap-5">
                  <div class="w-full flex">
                    <SelectInput
                  heading="Default currency"
                  :tooltip="true"
                  textFormate="capitalize"
                  toolText="Default currency"
                  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                  :options="['Paypal','Strap']"
                />            
                  </div>
                 </div> 
                  </template>
              </app-accordion>
               
              <div class="flex justify-end w-full mt-5">
                <SaveCancelForm version="1" :display="true" justify-orientation="end" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import SelectInput from "@/components/inputs/SelectInput";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

export default {
  name: "Subscription",
  components: {
    ExpandableBlockContent,
    SettingSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    SelectInput,
    AppAccordion
    
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
    
    };
  },
  computed: {
    pageContent: function () {
      return "Jarlsberg monterey jack danish fontina swiss cow gouda emmental parmesan. Edam mozzarella danish fontina cut the cheese port-salut mozzarella jarlsberg.";
    },
  },
};
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #404040;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #404040;
}
</style>
