<template>
  <div class="bg-white dark:bg-backgroundDark">
    <div class="mx-auto max-w-7xl py-10 sm:px-6 sm:py-12 lg:px-8">
      <div class="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-lg sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left text-left">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Showdeck {{pageName}}</h2>
          <p class="mt-6 text-lg leading-8 text-gray-300">This section of Showdeck is still under construction. Sign up to our mailing list to get our latest updates!</p>
          <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a href="https://showdeck.typeform.com/to/lyr8eydq" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Sign up</a>
            <router-link v-if="pageName=='Places'" :to="{ name: 'Places Preview' } " class="text-sm font-semibold leading-6 text-white">Preview <span aria-hidden="true">→</span></router-link>
            <router-link v-if="pageName=='Things'" :to="{ name: 'Things Preview' } " class="text-sm font-semibold leading-6 text-white">Preview <span aria-hidden="true">→</span></router-link>
          </div>
        </div>
        <div class="relative mt-16 h-80 lg:mt-8">
          <img class="absolute top-0 left-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10" :src="soonPhoto" alt="App screenshot" width="750" height="888" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  computed: {
    pageName: function () {
      return this.$route.name
    },
    soonPhoto: function() {
      return require('../assets/soon-'+this.$route.name+'.jpg')
    }
  }
}
</script>
