<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton :settingMenu="true"/>
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <SettingSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8 opacity-60 cursor-not-allowed pointer-events-none"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9">
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  :icon="['fas', 'user']"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-5 md:h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  User information
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>

              <div id="inputList" class="w-full mb-5">
                <div class="justify-between col-span-full mt-14"></div>

                <div class="flex w-full border-t border-gray">
                  <div class="p-2 w-2/6">Name</div>
                  <div class="p-2 w-3/6">{{ fullName }}</div>
                  <div class="p-2 w-1/6">Edit</div>
                </div>
                <div class="flex w-full border-t border-gray">
                  <div class="p-2 w-2/6 break-words">Username / URL slug</div>
                  <div class="p-2 w-3/6 break-words">{{ userURL }}</div>
                  <div class="p-2 w-1/6">Edit</div>
                </div>
                <div class="flex w-full border-t border-gray border-b">
                  <div class="p-2 w-2/6 break-words">Inactive user</div>
                  <div class="p-2 w-3/6 break-words">
                    Decide what happens if your are inactive for more than a year
                  </div>
                  <div class="p-2 w-1/6">Edit</div>
                </div>
              </div>

              <div class="w-full my-10" id="inputList-1" v-if="!isMobile">
                <p class="whitespace-nowrap text-lg pr-2 font-semibold">
                  Cookie settings
                </p>
                <p>This can save you time in the future.</p>
                <div class="w-1/2 my-10">
                <SelectInput
                  heading="Default cookie settings"
                  :tooltip="true"
                  textFormate="capitalize"
                  toolText="Default cookie settings"
                  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                  :options="['Allow all cookies','Block all cookies','Block third party cookies in Incognito','Block third-party cookies']"
                />
                </div>
              </div>
             
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Cookie settings</p>
                </template>
                <template v-slot:content>
                  <p>This can save you time in the future.</p>
                  <div class="flex w-full mb-5">
                    <SelectInput
                  heading="Default cookie settings"
                  :tooltip="true"
                  value="Allow all cookies"
                  textFormate="capitalize"
                  toolText="Default cookie settings"
                  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                  :options="['Allow all cookies','Block all cookies','Block third party cookies in Incognito','Block third-party cookies']"
                />
                  </div>
                </template>
              </app-accordion>

              <div class="flex justify-end w-full">
                <SaveCancelForm version="1" :display="true" justify-orientation="end" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import SelectInput from "@/components/inputs/SelectInput";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
const userURL = window.location.href;
export default {
  name: "Users",
  components: {
    ExpandableBlockContent,
    SettingSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    SelectInput,
    AppAccordion
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      userURL,
      fullName: store.getters.getUserFullName,
    };
  },
  computed: {
    pageContent: function () {
      return "Jarlsberg monterey jack danish fontina swiss cow gouda emmental parmesan. Edam mozzarella danish fontina cut the cheese port-salut mozzarella jarlsberg.";
    },
  },
};
</script>

<style scoped></style>
