<template>
  <div class="shadow-md bg-white rounded-lg py-1">
    <h4
      class="text-lg text-black dark:text-white font-medium items-center pb-2 pl-4 border-b border-gray-200 text-gray-700 dark:text-white font-medium items-center py-2 pl-4 border-b border-gray-200 text-gray-700 dark:text-white font-medium items-center py-2 pl-4 border-b border-gray-200"
    >
      Settings
    </h4>
    <ul class="relative edit-menu">
      <router-link
        active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark "
        :to="{ name: 'Settings', params: { id: id } }"
        class="pl-5 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
      >
        <font-awesome-icon
          :icon="['fas', 'user']"
          class="text-grey-400 text-md dark:text-white dark:hover:text-black font-normal text-black flex items-center py-1 px-0 h-4 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        {{ fullName }}</router-link
      >
      <router-link
        active-class="text-grey-500 hover:bg-gray-50  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingPrivacy', params: { id: id } }"
        class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
        >
        <svg width="24" height="24" viewBox="0 0 16 20" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-7 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0ZM14 10L14.002 18H2V10H14ZM5 8V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5Z" fill="currentColor" />
              </svg>
        Privacy</router-link
      >
      <router-link
        active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingNotification', params: { id: id } }"
        class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
        ><font-awesome-icon
          :icon="['far', 'bell']"
          class="text-grey-400 text-md dark:text-white font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        Notifications</router-link
      >
      <router-link
        active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingSecurity', params: { id: id } }"
        class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
        >
        <svg  viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-7 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out pl-1">
          <path d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 10.99H16C15.47 15.11 12.72 18.78 9 19.93V11H2V5.3L9 2.19V10.99Z" fill="currentColor"/>
      </svg>
        Security and login</router-link
      >

      <router-link
        active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingSubscription', params: { id: id } }"
        class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
      >
      <svg  viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-8 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out">
        <path d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7H13.1667C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="2" />
      </svg>
        Subscription</router-link
      >
      <router-link
        active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingRegional', params: { id: id } }"
        class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
      >
        <font-awesome-icon
          :icon="['fas', 'earth-americas']"
          class="text-grey-400 text-md dark:text-white font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        Regional settings
      </router-link>
      <router-link
        active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingNavigation', params: { id: id } }"
        class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"
      >
        <font-awesome-icon
          :icon="['fas', 'anchor']"
          class="text-grey-400 text-md dark:text-white dark-hover:text-black font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        Navigation</router-link
      >
    </ul>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: "SettingSidebar",
  // components: { },
  setup() {
    const store = useStore();

    return {
      id: computed(() => store.getters.getId),
      fullName: store.getters.getUserFullName,
    };
  },
};
</script>
<style>
.dark-mode .edit-menu a:hover svg {
  color: #1e1e1e;
}
</style>
