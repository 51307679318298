<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton  :settingMenu="true" />
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <SettingSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div
              class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60 cursor-not-allowed pointer-events-none"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  :icon="['fas', 'earth-americas']"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-5 md:h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Regional Settings
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>
              <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Language
                </p>
                <div class="w-full flex my-5 gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Default language"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Default language"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full mb-2" id="inputList-2" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Location
                </p>

                <div class="flex items-center mb-4">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-radio-1"
                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >Automatically filter to closest city</label
                  >
                </div>
                <div class="flex items-center mb-4">
                  <input
                    checked
                    id="default-radio-2"
                    type="radio"
                    value=""
                    name="default-radio"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-radio-2"
                    class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >Automatically filter to closest country</label
                  >
                </div>
                <div class="flex w-full">
                  <div class="flex-1 w-1/2">
                    <div class="flex items-center mb-4">
                      <input
                        checked
                        id="default-radio-2"
                        type="radio"
                        value=""
                        name="default-radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="default-radio-2"
                        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Automatically filter to selected distance from your
                        location</label
                      >
                    </div>
                  </div>
                  <div class="flex-1 w-1/2">
                    <SelectInput
                      textFormate="capitalize"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
                      :options="['5 km', 'Strap']"
                      value="5 km"
                    />
                  </div>
                </div>
                <div class="flex w-full">
                  <div class="flex-1 w-1/2">
                    <div class="flex items-center mb-4">
                      <input
                        checked
                        id="default-radio-2"
                        type="radio"
                        value=""
                        name="default-radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="default-radio-2"
                        class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Manually enter location</label
                      >
                    </div>
                  </div>
                  <div class="flex-1 w-1/2">
                    <SelectInput
                      textFormate="capitalize"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
                      :options="['Los Angeles', 'Strap']"
                      value="Los Angeles"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full mb-5" id="inputList-3" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Time & Date
                </p>
                <div class="w-full flex gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Time zone"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Time zone"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Time format"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Time format"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </div>
                <div class="w-full flex gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Week begins on"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Week begins on"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Date format"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Date format"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full mb-5" id="inputList-3" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Units of Measurement
                </p>
                <div class="w-full flex gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Length"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Length"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Area"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Area"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </div>
                <div class="w-full flex gap-5">
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Volume"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Volume"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-1/2 flex">
                    <SelectInput
                      heading="Sizes"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Sizes"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </div>
              </div>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">
                    Language
                  </p>
                </template>
                <template v-slot:content>
                  <div class="w-full col-span-2 flex items-end">
                    <SelectInput
                      heading="Default language"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Default language"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">
                    Location
                  </p>
                </template>
                <template v-slot:content>
                  <div class="flex items-center mb-4">
                    <input
                      id="default-radio-1"
                      type="radio"
                      value=""
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Automatically filter to closest city</label
                    >
                  </div>
                  <div class="flex items-center mb-4">
                    <input
                      checked
                      id="default-radio-2"
                      type="radio"
                      value=""
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Automatically filter to closest country</label
                    >
                  </div>
                  <div class="flex items-center mb-4">
                    <input
                      checked
                      id="default-radio-2"
                      type="radio"
                      value=""
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Automatically filter to selected distance from your location</label
                    >
                  </div>
                  <div class="flex items-center mb-4">
                    <input
                      checked
                      id="default-radio-2"
                      type="radio"
                      value=""
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Manually enter location</label
                    >
                  </div>
                  <div class="flex-1 w-full">
                    <SelectInput
                      textFormate="capitalize"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
                      :options="['5 km', 'Strap']"
                      value="5 km"
                    />
                  </div>
                  <div class="flex-1 w-full">
                    <SelectInput
                      textFormate="capitalize"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
                      :options="['Los Angeles', 'Strap']"
                      value="Los Angeles"
                    />
                  </div>
                </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                  <template v-slot:title>
                    <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Time & Date</p>
                    
                  </template>
                  <template v-slot:content>
                    <div class="w-full flex gap-5">
                     <SelectInput
                      heading="Time zone"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Time zone"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-full flex gap-5">
                    <SelectInput
                      heading="Time format"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Time format"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                 <div class="w-full flex gap-5">
                    <SelectInput
                      heading="Week begins on"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Week begins on"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-full flex gap-5">
                    <SelectInput
                      heading="Date format"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Date format"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
               </template>
              </app-accordion>
              <app-accordion class="mt-8" v-if="isMobile">
                  <template v-slot:title>
                    <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Units of Measurement</p>
                    
                  </template>
                  <template v-slot:content>
                    <div class="w-full flex gap-5">
                      <SelectInput
                      heading="Length"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Length"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-full flex gap-5">
                    <SelectInput
                      heading="Area"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Area"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-full flex gap-5">
                    <SelectInput
                      heading="Volume"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Volume"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                  <div class="w-full flex gap-5">
                    <SelectInput
                      heading="Sizes"
                      :tooltip="true"
                      textFormate="capitalize"
                      toolText="Sizes"
                      class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1 mt-5"
                      :options="['Paypal', 'Strap']"
                    />
                  </div>
                </template>
              </app-accordion>
              <div class="flex justify-end w-full">
                <SaveCancelForm version="1" :display="true" justify-orientation="end" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import SelectInput from "@/components/inputs/SelectInput";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
export default {
  name: "Agents",
  components: {
    ExpandableBlockContent,
    SettingSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    AppAccordion,
    SelectInput,
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      contacts: [
        {
          title: "Marie Kondo",
          subtittle: "Freewave",
          role: "Japan manager",
          contact: false,
          photos: true,
          programme: true,
          travel: true,
        },
        {
          title: "Gunnar Gísli",
          subtittle: "CAI",
          role: "Iceland agent",
          contact: true,
          photos: true,
          programme: false,
          travel: false,
        },
        {
          title: "Lily Allen",
          subtittle: "London International",
          role: "London agent",
          contact: false,
          photos: true,
          programme: false,
          travel: true,
        },
        {
          title: "John Cho",
          subtittle: "LA Dancers",
          role: "LA Agent",
          contact: false,
          photos: true,
          programme: false,
          travel: true,
        },
      ],
    };
  },
  computed: {
    pageContent: function () {
      return "The big cheese fromage cheese and wine. Swiss cottage cheese halloumi boursin roquefort cheese strings danish fontina blue castello. Stilton cheese strings red leicester everyone loves red leicester hard cheese cream cheese manchego. Manchego the big cheese feta airedale.";
    },
  },
};
</script>

<style scoped></style>
