<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton :settingMenu="true" />
      </div>

      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <SettingSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60 cursor-not-allowed pointer-events-none">
              <div class="flex items-center gap-2">
                <svg  viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-8 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out">
                    <path d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 10.99H16C15.47 15.11 12.72 18.78 9 19.93V11H2V5.3L9 2.19V10.99Z" fill="currentColor"/>
                </svg>

              
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Security & Login                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>

              <div class="w-full mb-5" id="inputList-1" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                  Password
                </p>
              
                <div class="w-1/2">
                  <div class="col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    :requiredIndicator="true" 
                    toolText="Current password"
                    heading="Current password"
                    class="w-full font-normal"
                  />
                  </div>
                </div>
                <div class="w-1/2 mt-5">
                  <div class="col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    :requiredIndicator="true" 
                    toolText="New password"         
                    heading="New password"
                    class="w-full font-normal"
                  />
                  </div>
                </div>
                <div class="w-1/2 mt-5">
                  <div class="col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    :requiredIndicator="true" 
                    toolText="Confirm new password"              
                    heading="Confirm new password"
                    class="w-full font-normal"
                  />
                  </div>
                </div>
              </div>
              <div class="w-full mb-5" id="inputList-2" v-if="!isMobile">
                <p
                  class="whitespace-nowrap text-lg pr-2 font-semibold my-5 dark:text-white"
                >
                Secondary email
                </p>
                <div class="w-1/2 col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    toolText="Secondary email"              
                    heading="Secondary email"
                    class="w-full font-normal"
                  />
                  </div>
              </div>

              <app-accordion class="mt-8" v-if="isMobile">

                <template v-slot:title>
                  <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Password</p>
                  
                </template>
                  <template v-slot:content>
                    <div class=" justify-between col-span-full mt-14">
                      <div class="w-full">
                  <div class="col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    :requiredIndicator="true" 
                    toolText="Current password"
                    heading="Current password"
                    class="w-full font-normal"
                  />
                  </div>
                </div>
                <div class="w-full mt-5">
                  <div class="col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    :requiredIndicator="true" 
                    toolText="New password"         
                    heading="New password"
                    class="w-full font-normal"
                  />
                  </div>
                </div>
                <div class="w-full mt-5">
                  <div class="col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    :requiredIndicator="true" 
                    toolText="Confirm new password"              
                    heading="Confirm new password"
                    class="w-full font-normal"
                  />
                  </div>
                </div>
                     </div>
                  </template>
              </app-accordion>

              <app-accordion class="mt-8" v-if="isMobile">
                  <template v-slot:title>
                    <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Secondary email</p>
                    
                  </template>
                  <template v-slot:content>
                    <div class="w-full col-span-2 flex items-end">
                    <SmallTextInput
                    :tooltip="true" 
                    toolText="Secondary email"              
                    heading="Secondary email"
                    class="w-full font-normal"
                  />
                  </div>
                  </template>
              </app-accordion>
           
              <div class="flex justify-end w-full mt-5">
                <SaveCancelForm version="1" :display="true" justify-orientation="end" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { computed } from 'vue'
import { useStore } from "vuex";
import { computed, ref } from "vue";
import SettingSidebar from "@/components/widgets/profileNavbar/SettingSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

export default {
  name: "SecurityLogin",
  components: {
    ExpandableBlockContent,
    SettingSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    SaveCancelForm,
    SmallTextInput,
    AppAccordion
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      
    
    };
  },
  computed: {
    pageContent: function () {
      return "Jarlsberg monterey jack danish fontina swiss cow gouda emmental parmesan. Edam mozzarella danish fontina cut the cheese port-salut mozzarella jarlsberg.";
    },
  },
};
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #404040;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #404040;
}
</style>
